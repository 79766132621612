export const salesFiscalConstants = {
    //Crear
    SALES_FISCAL_CREATE_REQUEST: 'SALES_FISCAL_CREATE_REQUEST',
    SALES_FISCAL_CREATE_SUCCESS: 'SALES_FISCAL_CREATE_SUCCESS',
    SALES_FISCAL_CREATE_FAILURE: 'SALES_FISCAL_CREATE_FAILURE',

    //Ventas offline
    SALES_FISCAL_CREATE_OFFLINE_REQUEST: 'SALES_FISCAL_CREATE_OFFLINE_REQUEST',
    SALES_FISCAL_CREATE_OFFLINE_SUCCESS: 'SALES_FISCAL_CREATE_OFFLINE_SUCCESS',
    SALES_FISCAL_CREATE_OFFLINE_FAILURE: 'SALES_FISCAL_CREATE_OFFLINE_FAILURE',

    SALES_FISCAL_GET_REQUEST: 'SALES_FISCAL_GET_REQUEST',
    SALES_FISCAL_GET_SUCCESS: 'SALES_FISCAL_GET_SUCCESS',
    SALES_FISCAL_GET_FAILURE: 'SALES_FISCAL_GET_FAILURE',

    SALES_FISCAL_UPDATE_REQUEST: 'SALES_FISCAL_UPDATE_REQUEST',
    SALES_FISCAL_UPDATE_SUCCESS: 'SALES_FISCAL_UPDATE_SUCCESS',
    SALES_FISCAL_UPDATE_FAILURE: 'SALES_FISCAL_UPDATE_FAILURE',

    SALES_FISCAL_TABLE_REQUEST: 'SALES_FISCAL_TABLE_REQUEST',
    SALES_FISCAL_TABLE_SUCCESS: 'SALES_FISCAL_TABLE_SUCCESS',
    SALES_FISCAL_TABLE_FAILURE: 'SALES_FISCAL_TABLE_FAILURE',

    SALES_FISCAL_TABLE_DETAIL_REQUEST: 'SALES_FISCAL_TABLE_DETAIL_REQUEST',
    SALES_FISCAL_TABLE_DETAIL_SUCCESS: 'SALES_FISCAL_TABLE_DETAIL_SUCCESS',
    SALES_FISCAL_TABLE_DETAIL_FAILURE: 'SALES_FISCAL_TABLE_DETAIL_FAILURE',

    SALES_FISCAL_SELECT_REQUEST: 'SALES_FISCAL_SELECT_REQUEST',
    SALES_FISCAL_SELECT_SUCCESS: 'SALES_FISCAL_SELECT_SUCCESS',
    SALES_FISCAL_SELECT_FAILURE: 'SALES_FISCAL_SELECT_FAILURE',

    SALES_FISCAL_DATA_REQUEST: 'SALES_FISCAL_DATA_REQUEST',
    SALES_FISCAL_DATA_SUCCESS: 'SALES_FISCAL_DATA_SUCCESS',
    SALES_FISCAL_DATA_FAILURE: 'SALES_FISCAL_DATA_FAILURE',

    SALES_FISCAL_DATA_UPDATE_REQUEST: 'SALES_FISCAL_DATA_UPDATE_REQUEST',
    SALES_FISCAL_DATA_UPDATE_SUCCESS: 'SALES_FISCAL_DATA_UPDATE_SUCCESS',
    SALES_FISCAL_DATA_UPDATE_FAILURE: 'SALES_FISCAL_DATA_UPDATE_FAILURE',

};

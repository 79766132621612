import { downloadConstants } from "../constants";
import { invoiceConstants } from "../constants/invoice.constants";
import { invoiceService } from '../services/invoice.service';
import { alertActions } from "./alert.actions";

export const invoiceActions = {

  //tickets generales
  dataTicketsInvoice(user, pageIndex, pageSize, sortBy, filters, isExcel = false) {
    return (dispatch) => {
      dispatch(request());

      invoiceService
        .invoiceTable(user, pageIndex, pageSize, sortBy, filters, isExcel)
        .then(
          (invoice) => {
            dispatch(success(invoice));
            if (isExcel) {
              dispatch(reset())
            }
          },
          (error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()))
          }
        )
    }

    function request() {
      return {
        type: !isExcel
          ? invoiceConstants.INVOICE_TABLE_REQUEST
          : downloadConstants.EXCEL_TABLE_REQUEST,
      };
    }
    function success(invoice) {
      if (!isExcel) {
        return { type: invoiceConstants.INVOICE_TABLE_SUCCESS, invoice };
      } else {
        let data = invoice;
        return { type: downloadConstants.EXCEL_TABLE_SUCCESS, data };
      }
    }
    function reset() {
      return { type: downloadConstants.EXCEL_TABLE_RESET };
    }
    function failure(error) {
      return {
        type: !isExcel
          ? invoiceConstants.INVOICE_TABLE_FAILURE
          : downloadConstants.EXCEL_TABLE_FAILURE,
        error,
      };

  }

}
}
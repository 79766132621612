export const apiUrl = 'https://apisistema.embutidosfattoria.com';
export const passphrase = 'app-development-22052020';
//frase de contraseÃ±a para data de ventas offline
export const passphraseData = 'data-development-sale-14082020';
export const passphraseSale = 'sale-development-sale-14082020';
//frase de contraseÃ±a para data de ventas offline
export const passphraseDataFiscal = 'data-fiscal-development-sale-14082020';
export const passphraseSaleFiscal = 'sale-fiscal-development-sale-14082020';
export const phoneAdmin = '+584122051963';


import { invoiceConstants } from "../constants/invoice.constants";

export default function invoice(state = { Controller: new AbortController() }, action){
    switch(action.type){
        case invoiceConstants.INVOICE_TABLE_REQUEST:
            return{
                loading: true
            }
        case invoiceConstants.INVOICE_TABLE_SUCCESS:
            return{
                table: action.invoice,
                loading:false
            }
        case invoiceConstants.INVOICE_TABLE_FAILURE:
            return{
                error:action.error,
                loading:false
            }
        
        default:
            return state;
    }
}